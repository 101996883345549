import React from 'react'
import {
  makeStyles,
  Typography,
  Grid,
} from '@material-ui/core'

import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  row: {
    alignSelf: 'center',
  },
  card: {
    backgroundColor: '#F5F5F5',
    padding: 20,
    borderRadius: 5,
    margin: 5,
    cursor: 'pointer',
  },

  text: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: 5,
  },
}))

const CardTotal = ({ title, qtd, onSelect }) => {
  const classes = useStyles()
  return (
    <Grid lg={2} sm={8} xs={8} item className={classes.card} onClick={onSelect}>
      <Grid container style={{ flexDirection: 'column' }} alignItems="center">
        <Grid item>
          <Typography className={classes.text}>{title}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.text}>{qtd}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

CardTotal.propTypes = {
  title: PropTypes.string.isRequired,
  qtd: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default CardTotal
