import React from 'react'
import PropTypes from 'prop-types'
import { Route, useHistory } from 'react-router-dom'

import useSecurity from '../useSecurity'
import { SlashScreen } from '../../components'

const PrivateRoute = (props) => {
  const history = useHistory()
  const { isLoggedIn } = useSecurity()

  const {
    layout: Layout, component: Component, ...rest
  } = props

  if (!isLoggedIn()) {
    history.push('/logout')
    return <SlashScreen />
  }

  return (
    <Route
      {...rest}
      render={() => (
        <Layout>
          <Component />
        </Layout>
      )}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
}

PrivateRoute.defaultProps = {
  component: undefined,
}

export default PrivateRoute
