import React, { useContext } from 'react'

const ComplementContext = React.createContext()

export const useBrokerComplement = () => {
  const {
    broker,
    actionNext,
    actionPrevious,
    setBroker,
    isBrokerPF,
    onNext,
    onPrevious,
  } = useContext(ComplementContext)

  return {
    broker,
    actionNext,
    actionPrevious,
    setBroker,
    isBrokerPF,
    onNext,
    onPrevious,
  }
}

export default ComplementContext
