import React from 'react'
import {
  Box,
  Button,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'

import { MIGRATION_STATUS } from '../../constants'
import useAuthClient from '../../clients/AuthClient'
import useBrokerClient from '../../clients/BrokerClient'
import { useAlert, AlertProvider } from '../../components'
import { useSecurityAction } from '../../store/ducks/security'

const useStyles = makeStyles(() => ({
  buttonLogin: {
    padding: '13px 30px',
  },
  welcome: {
    color: '#333333',
  },
}))

const Auth = () => {
  const classes = useStyles()
  const history = useHistory()
  const authClient = useAuthClient()
  const brokerClient = useBrokerClient()
  const { setUser } = useSecurityAction()
  const { addMsgDanger } = useAlert('auth')

  const pottencialLogin = (data) => {
    const {
      userName,
      accessToken,
      isAdminPottencial,
    } = data

    setUser({
      userName,
      accessToken,
      isPottencial: isAdminPottencial,
    })

    history.push('/')
  }

  const brokerLogin = (data) => {
    const {
      name,
      userName,
      accessToken,
      brokerDocument,
      brokerPersonId,
      isAdminBroker,
    } = data

    brokerClient().getStatus(brokerDocument, accessToken).then((response) => {
      const { statusRecadastro } = response.data

      if (MIGRATION_STATUS.MIGRATED_POTTENCIAL === statusRecadastro
        || MIGRATION_STATUS.MIGRATED_BROKER === statusRecadastro) {
        addMsgDanger(`Seu recadastramento já foi realizado! Clique <a href="${window.ENV.BROKER_URL}" >aqui</a> para realizar o seu login no Portal do Corretor`)
      } else {
        setUser({
          name,
          userName,
          accessToken,
          brokerPersonId,
          isPottencial: isAdminBroker,
        })

        history.push('/')
      }
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  const login = (data) => {
    const { isAdminPottencial } = data

    if (isAdminPottencial) {
      pottencialLogin(data)
    } else {
      brokerLogin(data)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      brokerCode: '',
    },
    validationSchema: Yup.object({
      brokerCode: Yup.number().required().required().test({
        test: (value) => value?.toString().length <= 30,
      }),
      email: Yup.string().email().max(200).required(),
      password: Yup.string().required(),
    }),
    onSubmit: (data) => {
      const {
        email,
        password,
        brokerCode,
      } = data

      const credential = {
        email,
        password,
        brokerCode: Number(brokerCode),
      }

      authClient().loginBroker(credential).then((response) => {
        const { message } = response.data

        if (message === 'OK') {
          login(response.data)
        } else {
          addMsgDanger(message)
        }
      }, (error) => {
        addMsgDanger(error.data)
      })
    },
  })

  return (
    <>
      <Box mb={2} mt={8} align="center">
        <Typography className={classes.welcome} variant="h5">Recadastramento de Corretoras</Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="body2">
          Insira os dados abaixo para acessar a nova experiência do Corretor.
        </Typography>
      </Box>

      <Box mb={4} mt={1}>
        <AlertProvider id="auth" />
      </Box>

      <TextField
        id="brokerCode"
        color="secondary"
        label="Código"
        title="Código"
        {...formik.getFieldProps('brokerCode')}
        error={formik.touched.brokerCode && !!formik.errors.brokerCode}
        helperText={formik.touched.brokerCode && formik.errors.brokerCode}
        fullWidth
      />

      <TextField
        id="email"
        color="secondary"
        label="Usuário"
        title="Usuário"
        {...formik.getFieldProps('email')}
        error={formik.touched.email && !!formik.errors.email}
        helperText={formik.touched.email && formik.errors.email}
        fullWidth
      />

      <TextField
        id="password"
        type="password"
        color="secondary"
        label="Senha"
        title="Senha"
        {...formik.getFieldProps('password')}
        error={formik.touched.password && !!formik.errors.password}
        helperText={formik.touched.password && formik.errors.password}
        fullWidth
      />

      <Box mt={2}>
        <Button
          size="large"
          color="primary"
          title="Entrar"
          variant="contained"
          className={classes.buttonLogin}
          onClick={() => formik.submitForm()}
          fullWidth
        >
          Entrar
        </Button>
      </Box>
    </>
  )
}

export default Auth
