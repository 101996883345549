import { useCallback } from 'react'
import ReactGA from 'react-ga'
import useUtils from './useUtils'

const useGoogleAnalytics = () => {
  const { isBlank } = useUtils()

  const event = useCallback((category, action, label = '') => {
    if (isBlank(window.ENV.GOOGLE_KEY)) {
      return
    }

    ReactGA.event({ category, action, label })
  }, [isBlank])

  return { event }
}

export default useGoogleAnalytics
