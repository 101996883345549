import React from 'react'
import {
  Box,
  CircularProgress,
  makeStyles,
} from '@material-ui/core'
import Logo from '../../assets/img/logo.jpg'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1000002,
  },
  logo: {
    width: 200,
    maxWidth: '100%',
  },
  circularProgress: {
    color: '#fff',
  },
}))

function SlashScreen() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        mb={6}
      >
        <img
          className={classes.logo}
          alt="Logo"
          src={Logo}
        />
      </Box>
      <CircularProgress className={classes.circularProgress} />
    </div>
  )
}

export default SlashScreen
