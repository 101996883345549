import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Card,
  Paper,
  Button,
  Typography,
  makeStyles,
  CardContent,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'
import DoneIcon from '@material-ui/icons/Done'
import VisibilityIcon from '@material-ui/icons/Visibility'

import useUtils from '../../hooks/useUtils'
import { Paginator, MessageBox } from '../../components'
import { DESCRIPTION_MIGRATION_STATUS, MIGRATION_STATUS } from '../../constants'

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
  },

  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  statusDone: {
    backgroundColor: '#DEF1E5',
    '& span': {
      color: '#46835B',
    },
  },
  statusDanger: {
    backgroundColor: '#F7EEEA',
    '& span': {
      color: '#BE431D',
    },
  },

  statusAttention: {
    backgroundColor: 'rgba(198, 163, 67, 0.1)',
    '& span': {
      color: '#C6A343',
    },
  },

  statusContainer: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  statusText: {
    padding: '5px 10px',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    minWidth: '150px',
    textAlign: 'center',
  },
}))

const BrokerTable = ({ brokers, nextPage, total }) => {
  const classes = useStyles()
  const history = useHistory()
  const [classStatus, setClassStatus] = useState({})
  const [brokerSelected, setBrokerSelected] = useState()
  const [openMigrationBroker, setOpenMigrationBroker] = useState(false)

  const {
    formatSUSEP,
    formatCPForCNPJ,
    formatDateAndHours,
    formatPhoneOrCellphone,
  } = useUtils()

  useEffect(() => {
    const data = {}
    data[MIGRATION_STATUS.NOT_IGRATED] = classes.statusDanger
    data[MIGRATION_STATUS.MIGRATED_POTTENCIAL] = classes.statusDone
    data[MIGRATION_STATUS.MIGRATED_BROKER] = classes.statusDone
    data[MIGRATION_STATUS.MIGRATIONG_POTTENCIAL] = classes.statusAttention
    data[MIGRATION_STATUS.MIGRATIONG_BROKER] = classes.statusAttention
    setClassStatus(data)
  }, [classes])

  const handleRedirectRenewal = (data) => {
    const { statusRenewal, personId } = data

    if (statusRenewal === MIGRATION_STATUS.MIGRATIONG_BROKER) {
      setBrokerSelected({ ...data })
      setOpenMigrationBroker(true)
    } else {
      history.push(`/broker/complement/${personId}`)
    }
  }

  return (
    <>
      <Box mt={2}>
        {total === 0 && (
          <Paper>
            <Box px={2} py={3}>Não foi possível encontrar registros para esta pesquisa.</Box>
          </Paper>
        )}

        {brokers.map((item, index) => (
          <Box key={index} mt={2} mb={2}>
            <Card className={classes.card} elevation={2}>
              <CardContent>
                <Grid
                  container
                  spacing={3}
                  justify="space-between"
                >
                  <Grid item lg={2} sm={12} xs={12} className={classes.grid}>
                    <Typography variant="body2" className={classes.title}>CNPJ/CPF:</Typography>
                    <Typography variant="body2">
                      {formatCPForCNPJ(item.document)}
                    </Typography>
                  </Grid>
                  <Grid item lg={3} sm={12} xs={12} className={classes.grid}>
                    <Typography variant="body2" className={classes.title}>CORRETORA:</Typography>
                    <Typography variant="body2">
                      {item.namePerson}
                    </Typography>
                  </Grid>
                  <Grid item lg={2} sm={12} xs={12} className={classes.grid}>
                    <Typography variant="body2" className={classes.title}>SUSEP:</Typography>
                    <Typography variant="body2">
                      {formatSUSEP(item.susepNumber)}
                    </Typography>
                  </Grid>
                  <Grid item lg={2} sm={12} xs={12} className={classes.grid}>
                    <Typography variant="body2" className={classes.title}>TELEFONE:</Typography>
                    <Typography variant="body2">
                      {formatPhoneOrCellphone(item.phoneNumber)}
                    </Typography>
                  </Grid>
                  <Grid className={classes.statusContainer} item lg={2} sm={12} xs={12}>
                    <Box className={`${classes.statusMessage} ${classes.statusText} ${classStatus[item.statusRenewal || 0]}`}>
                      <Typography variant="body2" component="span">
                        {DESCRIPTION_MIGRATION_STATUS[item.statusRenewal || 0]}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={1} sm={12} xs={12} className={classes.grid}>

                    {(!item.statusRenewal
                      || item.statusRenewal === MIGRATION_STATUS.NOT_IGRATED) && (
                      <Button
                        title="Migrar"
                        color="primary"
                        component={Link}
                        to={`/broker/complement/${item.personId}`}
                      >
                        Migrar
                      </Button>
                    )}

                    {(item.statusRenewal === MIGRATION_STATUS.MIGRATIONG_BROKER
                      || item.statusRenewal === MIGRATION_STATUS.MIGRATIONG_POTTENCIAL) && (
                        <Button
                          color="primary"
                          onClick={() => handleRedirectRenewal(item)}
                        >
                          <VisibilityIcon />
                        </Button>
                    )}

                    {(item.statusRenewal === MIGRATION_STATUS.MIGRATED_BROKER
                      || item.statusRenewal === MIGRATION_STATUS.MIGRATED_POTTENCIAL) && (
                        <DoneIcon />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Box>

      <Paginator totalResults={total} changePaginator={nextPage} />

      <MessageBox
        maxWidth="sm"
        opened={openMigrationBroker}
        handleClose={() => setOpenMigrationBroker(false)}
      >
        <>
          <Box mb={4}>
            <Typography align="center" variant="h6">
              <b>Migração iniciada pela corretora</b>
            </Typography>
          </Box>

          <Box mb={4}>
            <Typography align="center" variant="body2">
              A Corretora iniciou a migração do cadastro no dia
              <Box ml={1} component="span">{formatDateAndHours(brokerSelected?.dataRenewal) || ''}</Box>
              .
            </Typography>
          </Box>

          <Box mb={6}>
            <Typography align="center" variant="body2">
              {`Caso deseje realizar a migração para esta Corretora, basta clicar no
              botão "Migrar corretora" abaixo para acessar a tela de
              Recadastramento de Corretoras.`}
            </Typography>
          </Box>

          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} sm={5}>
              <Button
                title="Voltar"
                color="primary"
                variant="outlined"
                fullWidth
                onClick={() => setOpenMigrationBroker(false)}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button
                fullWidth
                color="primary"
                component={Link}
                variant="contained"
                title="Migrar corretora"
                to={`/broker/complement/${brokerSelected?.personId}`}
              >
                Migrar corretora
              </Button>
            </Grid>
          </Grid>
        </>
      </MessageBox>
    </>
  )
}

BrokerTable.propTypes = {
  total: PropTypes.number.isRequired,
  nextPage: PropTypes.func.isRequired,
  brokers: PropTypes.array.isRequired,
}

export default BrokerTable
