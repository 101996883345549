export const DEFAULT_THEME = {
  id: 'default',
  primary: '#F26522',
}

export const DESCRIPTION_MIGRATION_STATUS = {
  0: 'Não Migrado',
  1: 'Aguardando Migração pelo Administrador',
  2: 'Aguardando Migração pela Pottencial',
  3: 'Migrado pelo Administrador',
  4: 'Migrado pela Pottencial',
}

export const MIGRATION_STATUS = {
  NOT_IGRATED: 0,
  MIGRATIONG_BROKER: 1,
  MIGRATIONG_POTTENCIAL: 2,
  MIGRATED_BROKER: 3,
  MIGRATED_POTTENCIAL: 4,
}

export default { DEFAULT_THEME, MIGRATION_STATUS, DESCRIPTION_MIGRATION_STATUS }
