import { useCallback } from 'react'
import { isEmpty } from 'lodash'
import axios from 'axios'

import useUtils from '../../hooks/useUtils'

const useBrokerClient = () => {
  const { isBlank } = useUtils()

  const getBrokerById = useCallback((brokerPersonId) => axios.get(`/api/registration/${brokerPersonId}`), [])

  const createSponsor = useCallback((sponsor) => axios.post('/api/registration/sponsor/', sponsor, { headers: { context_id: sponsor.contextId } }), [])

  const editBrokerPF = useCallback((broker) => axios.put(`/api/registration/editBrokerPF/${broker.id}`, broker, { headers: { context_id: broker.contextId } }), [])

  const editBrokerPJ = useCallback((broker) => axios.put(`/api/registration/editBrokerPJ/${broker.id}`, broker, { headers: { context_id: broker.contextId } }), [])

  const complete = useCallback((brokerId, contextId) => axios.put(`/api/registration/editBroker/complete/${brokerId}`, {}, { headers: { context_id: contextId } }), [])

  const getRegistrationTotalizers = useCallback(() => axios.get('/api/registration/ReRegistrationTotalizers'), [])

  const getStatus = useCallback((document, currentToken) => axios.get(`/api/registration/getstatus?MainDocument=${document}`, { headers: { Authorization: `Bearer ${currentToken}` } }), [])

  const getBrokers = useCallback(({ pageNumber, document, statusRenewal }) => {
    const params = new URLSearchParams({
      'Page.PageSize': 10,
      'Page.Direction': 0,
      'Page.PageNumber': pageNumber,
    })

    if (!isBlank(document)) {
      params.append('Filter.Document', `${document}`)
    }

    if (!isEmpty(statusRenewal)) {
      statusRenewal.forEach((statusId) => params.append('Filter.StatusRenewal', statusId))
    }

    return axios.get(`/api/broker?${params}`)
  }, [isBlank])

  const migrationStatus = useCallback((status, mainDocument, contextId) => axios.post('/api/registration/changeregisterstatus', { status, mainDocument }, { headers: { context_id: contextId } }), [])

  const emailCheck = useCallback((data) => axios.post('/api/email/token-confirm', data), [])

  return useCallback(() => ({
    getRegistrationTotalizers,
    migrationStatus,
    getBrokerById,
    editBrokerPF,
    editBrokerPJ,
    createSponsor,
    emailCheck,
    getBrokers,
    getStatus,
    complete,
  }), [
    getRegistrationTotalizers,
    migrationStatus,
    getBrokerById,
    editBrokerPF,
    editBrokerPJ,
    createSponsor,
    getStatus,
    emailCheck,
    getBrokers,
    complete,
  ])
}

export default useBrokerClient
