import React from 'react'
import {
  Box,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import logo from '../../assets/img/logo.jpg'
import bgLogin from '../../assets/img/bg-login.png'
import ico from '../../assets/img/ico_pottencial.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  bgContainer: {
    backgroundImage: `url(${bgLogin})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '@media (max-width: 599px)': {
      backgroundPositionY: '-85px',
      backgroundRepeat: 'initial',
      height: '255px',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: theme.spacing(1),
    },
  },

  formContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
    color: '#333333',
    '@media (max-width: 599px)': {
      paddingTop: '95px',
    },
  },
  logo: {
    width: 160,
    margin: theme.spacing(2),
    display: 'flex',
    '& img': {
      maxWidth: '100%',
    },
    '@media (max-width: 599px)': {
      display: 'none',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 390,
    width: '100%',
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(3),
    },
  },
}))

const Login = ({ children }) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.bgContainer} sm={5}>
        <h1 className={classes.logo}><img src={logo} alt="logo" aria-label="Pottencial" /></h1>
      </Grid>
      <Grid item sm={7}>
        <Box className={classes.formContainer}>
          <Box className={classes.titleContainer}>
            <img src={ico} alt="Pottencial" aria-label="Pottencial" />
            <Typography variant="h6" className={classes.title} color="primary">PORTAL DO CORRETOR</Typography>
          </Box>

          <form noValidate autoComplete="off" className={classes.form}>
            {children}
          </form>
        </Box>
      </Grid>
    </Grid>
  )
}

Login.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Login
