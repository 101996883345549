import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { indexOf } from 'lodash'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { RiEqualizerLine } from 'react-icons/ri'
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  FormControl,
} from '@material-ui/core'

import {
  CPFOrCNPJInput,
  FilterContainer,
} from '../../components'
import useYup from '../../hooks/useYup'
import { DESCRIPTION_MIGRATION_STATUS, MIGRATION_STATUS } from '../../constants'

const useStyles = makeStyles((theme) => ({
  filterIcon: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1 / 2),
    fontSize: '1.5rem',
    transform: 'rotate(90deg)',
    borderRadius: 4,
  },

  formcontrol: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: '1px solid #E3E3E3',
  },

  button: {
    minWidth: 250,
  },

  inputColor: {
    border: 'none',
  },
}))

const DEFAULT_FILTER = {
  document: '',
  statusRenewal: [],
}

const BrokerFilter = ({ addFilter, filters }) => {
  const classes = useStyles()
  const { cpfOrCnpj } = useYup()
  const [filterBox, setFilterBox] = useState(false)

  const formik = useFormik({
    initialValues: { ...DEFAULT_FILTER },
    validationSchema: Yup.object({
      document: cpfOrCnpj,
      statusRenewal: Yup.array(),
    }),
    onSubmit: (data) => {
      addFilter(data)
      setFilterBox(false)
    },
  })

  const { setValues } = formik

  useEffect(() => {
    setValues({ ...filters })
  }, [filters, setValues])

  const handleClear = () => {
    formik.resetForm({ ...DEFAULT_FILTER })
    addFilter({ ...DEFAULT_FILTER })
  }

  const isStatusSelected = (status) => {
    const { statusRenewal } = formik.values
    let selecte = true

    status.every((value) => {
      selecte = indexOf(statusRenewal, value) !== -1
      let continueLoop = true

      if (selecte) {
        continueLoop = false
      }
      return continueLoop
    })

    return selecte
  }

  const toggleStatus = (status) => {
    const { statusRenewal } = formik.values
    let data = [...statusRenewal]

    if (isStatusSelected(status)) {
      data = data.filter((item) => status.filter((value) => item === value).length === 0)
    } else {
      data.push(...status)
    }

    formik.setFieldValue('statusRenewal', data)
  }

  const variantStatus = (status) => (isStatusSelected(status) ? 'contained' : 'outlined')

  return (
    <>
      <Button className={classes.filterIcon}>
        <RiEqualizerLine onClick={() => setFilterBox(true)} />
      </Button>

      <FilterContainer
        open={filterBox}
        onClean={handleClear}
        onClose={() => setFilterBox(false)}
        onSearch={() => formik.submitForm()}
      >
        <Box mb={2}>
          <CPFOrCNPJInput
            id="document"
            color="secondary"
            label="CPF ou CNPJ"
            title="CPF ou CNPJ"
            {...formik.getFieldProps('document')}
            error={formik.touched.document && !!formik.errors.document}
            helperText={formik.touched.document && formik.errors.document}
            fullWidth
          />
        </Box>

        <Box mb={2}>
          <FormControl className={classes.formcontrol} fullWidth>
            <Typography paragraph>Situação:</Typography>
            <Grid container spacing={1} alignItems="center" justify="space-between">
              <Grid item lg="auto" xs={12}>
                <Button
                  color="primary"
                  className={classes.button}
                  variant={variantStatus([MIGRATION_STATUS.MIGRATED_POTTENCIAL])}
                  title={DESCRIPTION_MIGRATION_STATUS[MIGRATION_STATUS.MIGRATED_POTTENCIAL]}
                  onClick={() => toggleStatus([MIGRATION_STATUS.MIGRATED_POTTENCIAL])}
                >
                  {DESCRIPTION_MIGRATION_STATUS[MIGRATION_STATUS.MIGRATED_POTTENCIAL]}
                </Button>
              </Grid>

              <Grid item lg="auto" xs={12}>
                <Button
                  color="primary"
                  className={classes.button}
                  variant={variantStatus([MIGRATION_STATUS.MIGRATED_BROKER])}
                  title={DESCRIPTION_MIGRATION_STATUS[MIGRATION_STATUS.MIGRATED_BROKER]}
                  onClick={() => toggleStatus([MIGRATION_STATUS.MIGRATED_BROKER])}
                >
                  {DESCRIPTION_MIGRATION_STATUS[MIGRATION_STATUS.MIGRATED_BROKER]}
                </Button>
              </Grid>

              <Grid item lg="auto" xs={12}>
                <Button
                  color="primary"
                  className={classes.button}
                  title="Aguardando Migração"
                  variant={variantStatus([
                    MIGRATION_STATUS.MIGRATIONG_POTTENCIAL,
                    MIGRATION_STATUS.MIGRATIONG_BROKER,
                  ])}
                  onClick={() => toggleStatus([
                    MIGRATION_STATUS.MIGRATIONG_POTTENCIAL,
                    MIGRATION_STATUS.MIGRATIONG_BROKER,
                  ])}
                >
                  Aguardando Migração
                </Button>
              </Grid>

              <Grid item lg="auto" xs={12}>
                <Button
                  color="primary"
                  className={classes.button}
                  variant={variantStatus([MIGRATION_STATUS.NOT_IGRATED])}
                  title={DESCRIPTION_MIGRATION_STATUS[MIGRATION_STATUS.NOT_IGRATED]}
                  onClick={() => toggleStatus([MIGRATION_STATUS.NOT_IGRATED])}
                >
                  {DESCRIPTION_MIGRATION_STATUS[MIGRATION_STATUS.NOT_IGRATED]}
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </Box>
      </FilterContainer>
    </>
  )
}

BrokerFilter.propTypes = {
  addFilter: PropTypes.func,
  filters: PropTypes.object,
}

BrokerFilter.defaultProps = {
  addFilter: () => { },
  filters: {},
}

export default BrokerFilter
