import React, {
  useMemo,
  useState,
  useCallback,
} from 'react'
import Box from '@material-ui/core/Box'
import Step from '@material-ui/core/Step'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core'
import Stepper from '@material-ui/core/Stepper'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import { useHistory, useParams } from 'react-router-dom'

import {
  Resolve,
  useAlert,
  ModalInfo,
  MessageBox,
  StepControl,
  CPF_SIZE_WITHOUT_MASK,
} from '../../components'
import Bank from './Bank'
import Sponsor from './Sponsor'
import PersonBroker from './PersonBroker'
import CompanyBroker from './CompanyBroker'
import { MIGRATION_STATUS } from '../../constants'
import ComplementContext from './ComplementContext'
import useSecurity from '../../security/useSecurity'
import useUserClient from '../../clients/UserClient'
import useBrokerClient from '../../clients/BrokerClient'
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics'
import { useSecurityAction } from '../../store/ducks/security'
import thumbCadastro from '../../assets/img/ico-alert-success.svg'

const useStyles = makeStyles((theme) => ({
  migrationUsersModal: {
    '& .MuiPaper-root': {
      maxWidth: 610,
    },
  },
  paper: {
    padding: theme.spacing(4),
    height: '100%',
  },
  dataContainer: {
    '& .paper': {
      padding: theme.spacing(4, 3),
    },
  },
  userContainer: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 300,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 400,
    },
  },
  btn: {
    width: '100%',
  },
  step: {
    flexDirection: 'column',
    width: 135,
    textAlign: 'center',
    '& .MuiStepLabel-iconContainer': {
      paddingRight: 0,
    },
    '& .MuiStepLabel-labelContainer': {
      textTransform: 'uppercase',
      marginTop: 4,
      '& .MuiStepLabel-label': {
        fontWeight: 600,
      },
    },
    '& .MuiStepLabel-completed, & .MuiStepLabel-active': {
      color: theme.palette.primary.main,
    },
  },
  containerStepper: {
    maxWidth: 550,
    margin: 'auto',
    marginBottom: theme.spacing(3),
    width: '100%',
    '& .MuiStepConnector-root': {
      height: 20,
      margin: -40,
      maxWidth: 120,
    },
    '& .MuiPaper-root': {
      justifyContent: 'center',
    },
  },
}))

const StepType = {
  BROKER: 0,
  BANK_DATA: 1,
  SPONSOR: 2,
}

const BrokerComplement = () => {
  const { Provider } = ComplementContext
  const { brokerPersonId } = useParams()
  const { isPottencial, getBrokerPersonId } = useSecurity()

  const userClient = useUserClient()
  const { addMsgDanger } = useAlert()
  const brokerClient = useBrokerClient()
  const { event } = useGoogleAnalytics()
  const { cleanUser } = useSecurityAction()

  const classes = useStyles()
  const history = useHistory()

  const [steps, setSteps] = useState([])
  const [broker, setBroker] = useState({})
  const [stepsRef, setStepsRef] = useState({})
  const [display, setDisplay] = useState('none')
  const [actionNext, setActionNext] = useState(false)
  const [openConcluded, setOpenConcluded] = useState(false)
  const [actionPrevious, setActionPrevious] = useState(false)
  const [activeStep, setActiveStep] = useState(StepType.BROKER)
  const [openMigrationUsers, setOpenMigrationUsers] = useState(false)

  const isBrokerPF = useCallback((data = {}) => {
    const { documentNumber = '' } = data
    return documentNumber.length === CPF_SIZE_WITHOUT_MASK
  }, [])

  const onSubmitStep = () => {
    const stepRef = stepsRef[activeStep]
    stepRef.current.onSubmit().then((message) => addMsgDanger(message))
  }

  const handleResolve = useMemo(() => ({
    broker: () => new Promise((resolve, reject) => {
      brokerClient().getBrokerById(brokerPersonId).then((response) => {
        const { contextId, name, documentNumber } = response.data
        const statusId = isPottencial() ? MIGRATION_STATUS.MIGRATIONG_POTTENCIAL
          : MIGRATION_STATUS.MIGRATIONG_BROKER

        brokerClient().migrationStatus(statusId, documentNumber, contextId).then(() => {
          event('Recadastro', 'Iniciado', name || '')
        })

        resolve(response.data)
      }, (error) => {
        reject()
        addMsgDanger(error.data)
      })
    }),
  }), [brokerClient, addMsgDanger, isPottencial, event, brokerPersonId])

  const handleLoaded = useCallback((data, resolve) => {
    setBroker(data.broker)

    const stepsControl = {}
    stepsControl[StepType.BROKER] = React.createRef()
    stepsControl[StepType.BANK_DATA] = React.createRef()

    if (isBrokerPF(data.broker)) {
      setSteps(['Corretor', 'Dados bancários'])
    } else {
      stepsControl[StepType.SPONSOR] = React.createRef()
      setSteps(['Corretora', 'Dados bancários', 'Responsável'])
    }
    setStepsRef(stepsControl)
    resolve()

    setTimeout(() => {
      const currentRef = stepsControl[StepType.BROKER]

      if (currentRef.current) {
        currentRef.current.onOpen().then(() => {
          setDisplay('block')
        })
      }
    })
  }, [isBrokerPF])

  const success = () => {
    const { contextId, name, documentNumber } = broker

    const statusId = isPottencial() ? MIGRATION_STATUS.MIGRATED_POTTENCIAL
      : MIGRATION_STATUS.MIGRATED_BROKER

    brokerClient().migrationStatus(statusId, documentNumber, contextId).then(() => {
      event('Recadastro', 'Concluido', name || '')
    })

    userClient().getUsers(contextId).then((response) => {
      const { total = 0 } = response.data

      if (total === 0) {
        setOpenConcluded(true)
      } else {
        setOpenMigrationUsers(true)
      }
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  const handleRedirectBroker = () => {
    window.location.href = window.ENV.BROKER_URL
    setTimeout(() => cleanUser())
  }

  const handleRedirectUser = () => {
    const { contextId } = broker
    history.replace(`/user/list/${contextId}`)
  }

  const handleNext = () => {
    setActionNext(true)
    setActionPrevious(false)
    setTimeout(() => onSubmitStep())
  }

  const handlePrevious = () => {
    if (activeStep === StepType.BROKER) {
      const personId = getBrokerPersonId()

      if (personId) {
        history.replace('/logout')
      } else {
        history.push('/broker/list')
      }
      return
    }

    setActionNext(false)
    setActionPrevious(true)
    setTimeout(() => onSubmitStep())
  }

  const onNext = () => {
    let end = activeStep === StepType.SPONSOR

    if (isBrokerPF(broker)) {
      end = activeStep === StepType.BANK_DATA
    }

    if (end) {
      success()
    } else {
      const nextStep = activeStep + 1
      const stepRef = stepsRef[nextStep]
      const currentRef = stepsRef[activeStep]

      stepRef.current.onOpen().then(() => {
        setActiveStep(nextStep)
        currentRef.current.onClose()
      })
    }
  }

  const onPrevious = () => {
    const previousStep = activeStep - 1
    const stepRef = stepsRef[previousStep]
    const currentRef = stepsRef[activeStep]

    stepRef.current.onOpen().then(() => {
      setActiveStep(previousStep)
      currentRef.current.onClose()
    })
  }

  return (
    <Provider
      value={{
        broker,
        actionNext,
        actionPrevious,
        setBroker,
        isBrokerPF,
        onNext,
        onPrevious,
      }}
    >
      <Resolve
        onLoaded={handleLoaded}
        resolve={handleResolve}
      >
        <main>
          <Box display={display}>
            <Box mb={2}>
              <Typography variant="h5">Recadastramento de Corretoras</Typography>
            </Box>

            <Box mb={4}>
              <Typography variant="body2">
                Favor revisar os dados cadastrais e completar os
                campos faltantes para realizar o recadastramento
                da Corretora.
              </Typography>
            </Box>

            <Grid container spacing={2} justify="center">

              {/* STEPS */}
              <Hidden xsDown>
                <Box className={classes.containerStepper}>
                  <Stepper activeStep={activeStep}>
                    {steps.map((label) => {
                      const labelProps = {
                        classes: {
                          horizontal: classes.step,
                        },
                      }
                      return (
                        <Step key={label}>
                          <StepLabel {...labelProps}>
                            {label}
                          </StepLabel>
                        </Step>
                      )
                    })}
                  </Stepper>
                </Box>
              </Hidden>

              {/* CONTEUDO */}

              {/* PJ */}
              {!isBrokerPF(broker) && (
                <Grid item xs={12} lg={10} className={classes.dataContainer}>
                  <CompanyBroker ref={stepsRef[StepType.BROKER]} />
                  <Bank ref={stepsRef[StepType.BANK_DATA]} />
                  <Sponsor ref={stepsRef[StepType.SPONSOR]} />
                </Grid>
              )}

              {/* PF */}
              {isBrokerPF(broker) && (
                <Grid item xs={12} lg={10} className={classes.dataContainer}>
                  <PersonBroker ref={stepsRef[StepType.BROKER]} />
                  <Bank ref={stepsRef[StepType.BANK_DATA]} />
                </Grid>
              )}

              {/* CONTROLES */}
              <Grid item xs={12} lg={10}>
                <Box>
                  <StepControl
                    steps={steps}
                    activeStep={activeStep}
                    handleNext={handleNext}
                    handleBack={handlePrevious}
                    finishText="Concluir"
                  />
                </Box>
              </Grid>
            </Grid>

            {/* MODAL DE CONCLUSÃO */}
            <MessageBox
              labelPrimary="OK"
              thumb={thumbCadastro}
              opened={openConcluded}
              handleClose={handleRedirectBroker}
              handlePrimary={handleRedirectBroker}
              title="Recadastramento realizado com sucesso!"
            />

            <ModalInfo
              close={false}
              open={openMigrationUsers}
              className={classes.migrationUsersModal}
            >
              <>
                <Box mb={4}>
                  <Typography align="center" variant="h6">
                    O cadastro da Corretora foi migrado com sucesso!
                  </Typography>
                </Box>

                <Box mb={4}>
                  <Typography align="center" variant="body2">
                    Para os demais usuários da corretora terem acesso ao novo Portal,
                    é necessário que sejam cadastrados no sistema.
                  </Typography>
                </Box>

                <Grid container justify="center" spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={handleRedirectUser}
                    >
                      Vamos começar?
                    </Button>
                  </Grid>
                </Grid>
              </>
            </ModalInfo>
          </Box>
        </main>
      </Resolve>
    </Provider>
  )
}

export default BrokerComplement
