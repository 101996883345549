import { useCallback } from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import produce from 'immer'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const INITIAL_STATE = {
  users: [],
  blacklist: [],
}

export const Types = {
  USERS: 'user/users',
  BLACKLIST: 'user/blacklist',
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.USERS: {
      const { users } = action.payload

      return produce(state, (draft) => {
        draft.users = users
      })
    }

    case Types.BLACKLIST: {
      const { blacklist } = action.payload

      return produce(state, (draft) => {
        draft.blacklist = blacklist
      })
    }

    default: {
      return state
    }
  }
}

export const useUserAction = () => {
  const dispatch = useDispatch()

  const { users, blacklist } = useSelector(({ user }) => ({
    users: user.users,
    blacklist: user.blacklist,
  }))

  const setUser = useCallback((user) => {
    const data = users.filter((item) => item.userId !== user.userId)

    dispatch({
      type: Types.USERS,
      payload: { users: [...data, { ...user }] },
    })
  }, [dispatch, users])

  const setBlacklist = useCallback((user) => {
    const data = blacklist.filter((item) => item.userId !== user.userId)

    dispatch({
      type: Types.BLACKLIST,
      payload: { blacklist: [...data, { ...user }] },
    })
  }, [dispatch, blacklist])

  const deleteUser = useCallback((user) => {
    const data = users.filter((item) => item.userId !== user.userId)

    dispatch({
      type: Types.USERS,
      payload: { users: [...data] },
    })
  }, [dispatch, users])

  return {
    setUser,
    deleteUser,
    setBlacklist,
  }
}

export default persistReducer({
  key: 'user',
  storage,
}, reducer)
