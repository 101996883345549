import React from 'react'
import PropTypes from 'prop-types'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import CloseIcon from '@material-ui/icons/Close'
import Backdrop from '@material-ui/core/Backdrop'
import { makeStyles, Paper, Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1),
    height: `calc(100% - ${theme.spacing(2)}px)`,
  },
  paper: {
    width: '66%',
    overflow: 'auto',
    maxHeight: '100%',
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '96%',
    },
  },

  close: {
    cursor: 'pointer',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

const ModalInfo = ({
  children, open, close, onClose, ...props
}) => {
  const classes = useStyles()
  return (
    <Modal
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      open={open}
      {...props}
    >
      <Fade in={open}>
        <Box className={classes.modal}>
          <Paper className={classes.paper}>
            {close && (
              <CloseIcon
                title="Fechar"
                onClick={onClose}
                className={classes.close}
              />
            )}

            {children}
          </Paper>
        </Box>
      </Fade>
    </Modal>
  )
}

ModalInfo.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.element.isRequired,
}

ModalInfo.defaultProps = {
  open: false,
  close: true,
  onClose: () => {},
}

export default ModalInfo
