import { useCallback } from 'react'
import produce from 'immer'
import { useDispatch } from 'react-redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { DEFAULT_THEME } from '../../constants'

const INITIAL_STATE = {
  user: {
    color: DEFAULT_THEME,
  },
}

export const Types = {
  USER: 'security/USER',
}

const reducer = (state = INITIAL_STATE, action) => {
  if (action.type === Types.USER) {
    const { user } = action.payload

    return produce(state, (draft) => {
      draft.user = user
    })
  }

  return state
}

export const useSecurityAction = () => {
  const dispatch = useDispatch()

  const setUser = useCallback((user) => dispatch({
    type: Types.USER,
    payload: { user },
  }), [dispatch])

  const cleanUser = useCallback(() => dispatch({
    type: Types.USER,
    payload: { user: {} },
  }), [dispatch])

  return {
    setUser,
    cleanUser,
  }
}

export default persistReducer({
  key: 'security',
  storage,
}, reducer)
