import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import useUtils from './hooks/useUtils'
import { store, persist } from './store'
import { ErrorBoundary } from './components'

const Main = () => {
  const { isBlank } = useUtils()

  useEffect(() => {
    if (isBlank(window.ENV.GOOGLE_KEY)) {
      return
    }

    ReactGA.initialize(window.ENV.GOOGLE_KEY)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [isBlank])

  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persist}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </>
  )
}

ReactDOM.render(
  <Main />,
  document.getElementById('root'),
)
