import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import {
  isNaN,
  isNull,
  isEmpty,
  isUndefined,
} from 'lodash'
import { useFormik } from 'formik'
import Box from '@material-ui/core/Box'
import ReactTooltip from 'react-tooltip'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import {
  useAlert,
  CPFInput,
  PhoneInput,
  RadioButton,
} from '../../components'
import useYup from '../../hooks/useYup'
import PermissionModal from './PermissionModal'
import useUserClient from '../../clients/UserClient'
import { useUserAction } from '../../store/ducks/user'

import icoEdit from '../../assets/img/ico_edit.svg'

const DEFAULT_PROFILE = {
  name: '',
  email: '',
  office: '',
  phoneNumber: '',
  mainDocument: '',
  administrator: '',
}

const useStyles = makeStyles((theme) => ({
  contentTools: {
    textAlign: 'end',
  },
  btnDelete: {
    fill: theme.palette.primary.main,
  },
}))

const UserForm = (props) => {
  const {
    user,
    index,
    functionalities,
  } = props

  const classes = useStyles()
  const { addMsgDanger } = useAlert()
  const userClient = useUserClient()
  const [disabled, setDisabled] = useState(false)
  const [openedPermission, setOpenedPermission] = useState(false)
  const [userFunctionalities, setUserFunctionalities] = useState([])

  const {
    setUser,
    setBlacklist,
    deleteUser,
  } = useUserAction()

  const {
    cpf: cpfRule,
    phoneOrCellphone: phoneRule,
  } = useYup()

  const formik = useFormik({
    initialValues: { ...DEFAULT_PROFILE },
    validationSchema: Yup.object({
      active: Yup.boolean().required(),
      mainDocument: cpfRule.required(),
      phoneNumber: phoneRule.required(),
      name: Yup.string().max(200).required(),
      email: Yup.string().email().required(),
      office: Yup.string().max(60).required(),
      administrator: Yup.string().required(),
    }),
    onSubmit: (data) => {
      const { id, userId } = user

      const {
        name,
        email,
        office,
        phoneNumber,
        mainDocument,
        administrator,
      } = data

      let adminVal

      if (!isEmpty(administrator)) {
        adminVal = administrator === 's'
      }

      const current = {
        id,
        name,
        email,
        office,
        userId,
        phoneNumber,
        mainDocument,
        reviewed: true,
        administrator: adminVal,
        returnUrl: window.ENV.BROKER_URL,
        functionalities: userFunctionalities.map((item) => item.id),
      }

      userClient().checkUserRegister(current).then(() => {
        setUser(current)
        setDisabled(true)
      }, (response) => {
        addMsgDanger(response.data)
      })
    },
  })

  const { setValues } = formik

  useEffect(() => {
    const {
      name,
      email,
      office,
      active,
      phoneNumber,
      mainDocument,
      administrator,
      reviewed,
    } = user

    let adminVal = ''

    if (!isNull(administrator) && !isNaN(administrator) && !isUndefined(administrator)) {
      adminVal = administrator ? 's' : 'n'
    }

    setValues({
      name: name || '',
      email: email || '',
      office: office || '',
      active: active || false,
      phoneNumber: phoneNumber || '',
      mainDocument: mainDocument || '',
      administrator: adminVal,
    })

    setDisabled(reviewed || false)
    setUserFunctionalities(user.functionalities || [])
  }, [user, setValues])

  const handlePermission = (data) => {
    setOpenedPermission(false)
    setUserFunctionalities([...data])
  }

  const handlePermissionCancel = () => {
    setOpenedPermission(false)

    if (isEmpty(userFunctionalities)) {
      formik.setFieldValue('administrator', 's')
    }
  }

  const handleSave = () => {
    formik.validateForm().then((errors) => {
      if (!isEmpty(errors)) {
        addMsgDanger({ message: 'Verifique os campos em destaque antes de prosseguir.' })
      }

      formik.submitForm()
    })
  }

  return (
    <>
      <Box component={Paper} py={3} px={4} mb={3}>
        <Box className={classes.contentTools}>
          {disabled && (
            <IconButton
              title="Editar dados"
              onClick={() => setDisabled(false)}
            >
              <img src={icoEdit} alt="Editar dados" title="Editar dados" />
            </IconButton>
          )}

          <IconButton
            title="Excluir dados"
            onClick={() => {
              deleteUser(user)
              setBlacklist(user)
            }}
          >
            <DeleteIcon className={classes.btnDelete} />
          </IconButton>
        </Box>
        <Grid container spacing={8}>
          <Grid item sm={4} xs={12}>
            <TextField
              id={`name-${index}`}
              color="secondary"
              {...formik.getFieldProps('name')}
              label="Nome:"
              title="Nome"
              error={formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              InputProps={{
                disabled,
              }}
              fullWidth
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <CPFInput
              id={`mainDocument-${index}`}
              color="secondary"
              label="CPF:"
              title="CPF"
              fullWidth
              InputProps={{
                disabled,
              }}
              {...formik.getFieldProps('mainDocument')}
              error={formik.touched.mainDocument && !!formik.errors.mainDocument}
              helperText={formik.touched.mainDocument && formik.errors.mainDocument}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <PhoneInput
              id={`phoneNumber-${index}`}
              color="secondary"
              label="Telefone:"
              title="Telefone"
              fullWidth
              InputProps={{
                disabled,
              }}
              {...formik.getFieldProps('phoneNumber')}
              error={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              id={`email-${index}`}
              color="secondary"
              label="E-mail:"
              title="E-mail"
              fullWidth
              InputProps={{
                disabled,
              }}
              {...formik.getFieldProps('email')}
              error={formik.touched.email && !!formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            <TextField
              id={`office-${index}`}
              color="secondary"
              label="Cargo:"
              title="Cargo"
              fullWidth
              InputProps={{
                disabled,
              }}
              {...formik.getFieldProps('office')}
              error={formik.touched.office && !!formik.errors.office}
              helperText={formik.touched.office && formik.errors.office}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InputLabel>
              Perfil administrador:
              <Box
                ml={1}
                data-html
                component="span"
                style={{ cursor: 'help' }}
                data-tip="O usuário Administrador de uma Corretora<br /> possui acesso a todas as funcionalidades<br /> do sistema."
              >
                <HelpOutlineIcon fontSize="small" />
              </Box>
            </InputLabel>

            <RadioGroup
              row
              name="administrator"
              aria-label="administrator"
              {...formik.getFieldProps('administrator')}
            >
              <FormControlLabel
                value="s"
                label="Sim"
                disabled={disabled}
                onChange={() => setUserFunctionalities([])}
                control={<RadioButton />}
              />
              <FormControlLabel
                value="n"
                label="Não"
                disabled={disabled}
                onChange={() => setOpenedPermission(true)}
                control={<RadioButton />}
              />
            </RadioGroup>
            <FormHelperText
              hidden={!formik.touched.administrator || !formik.errors.administrator}
              error={formik.touched.administrator && !!formik.errors.administrator}
            >
              {formik.errors.administrator}
            </FormHelperText>
          </Grid>
        </Grid>

        {!disabled && (
          <Box mt={3}>
            <Grid container justify="flex-end">
              <Grid item xs={12} sm={2}>
                <Button
                  size="large"
                  color="primary"
                  title="Salvar"
                  variant="contained"
                  onClick={handleSave}
                  fullWidth
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>

      <PermissionModal
        opened={openedPermission}
        functionalities={functionalities}
        userFunctionalities={userFunctionalities}
        onSave={handlePermission}
        onCancel={handlePermissionCancel}
      />

      <ReactTooltip place="top" type="dark" />
    </>
  )
}

UserForm.propTypes = {
  user: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  functionalities: PropTypes.array.isRequired,
}

export default UserForm
