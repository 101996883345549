import { useCallback } from 'react'
import axios from 'axios'

const useUserClient = () => {
  const checkUserRegister = useCallback((data) => axios.post('/api/users/check', data, { headers: { context_id: data.id } }), [])

  const migrateUsers = useCallback((data) => axios.post('/api/users', data, { headers: { context_id: data.contextId } }), [])

  const getUsers = useCallback((contextId) => axios.get('/api/users', { headers: { context_id: contextId } }), [])

  return useCallback(() => ({
    getUsers,
    migrateUsers,
    checkUserRegister,
  }), [
    getUsers,
    migrateUsers,
    checkUserRegister,
  ])
}

export default useUserClient
