import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import Main from './Main'
import Footer from './Footer'
import Header from './header/Header'

const Master = (props) => {
  const { children } = props
  const history = useHistory()
  const [logoClient] = useState(null)
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const sair = () => history.push('/logout')

  return (
    <>
      <Header
        handleDrawerToggle={handleDrawerToggle}
        logoClient={logoClient}
        logout={sair}
      />
      <Main
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        logout={sair}
      >
        {children}
      </Main>

      <Footer />
    </>
  )
}

Master.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Master
