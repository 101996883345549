import React from 'react'
import {
  Typography, Box, Button, makeStyles,
} from '@material-ui/core'
import { Link, useParams } from 'react-router-dom'

import notFound from '../../assets/img/ico-error.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(4),
  },
  image: {
    maxWidth: '100%',
  },
  title: {
    color: '#595959',
    fontWeight: 600,
  },
  status: {
    top: '47px',
    left: '18px',
    position: 'relative',
    fontSize: '2.4rem',
    fontWeight: '900',
    color: '#c0ccd8',
    fontFamily: 'Arial, Helvetica, sans-serif',
  },
}))

const Error = () => {
  const classes = useStyles()
  const { status } = useParams()

  return (
    <Box className={classes.root}>
      <span className={classes.status}>{status}</span>
      <img
        alt="Under development"
        className={classes.image}
        src={notFound}
      />
      <Typography variant="h1" className={classes.title}>Oops!</Typography>
      <Box my={5} color="#595959">
        <Typography variant="h5">Desculpe, parece que algo deu errado…</Typography>
      </Box>
      <Button color="primary" variant="contained" to="/" component={Link}>Voltar a página inicial</Button>
    </Box>
  )
}

export default Error
