import { useCallback } from 'react'
import axios from 'axios'

const useLocationClient = () => {
  const getAddressByZipCode = useCallback((zipCode) => axios.get(`/api/resources/correios/${zipCode}`), [])

  return useCallback(() => ({
    getAddressByZipCode,
  }), [
    getAddressByZipCode,
  ])
}

export default useLocationClient
