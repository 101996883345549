import { useCallback } from 'react'
import axios from 'axios'

const useAuthClient = () => {
  const loginBroker = useCallback((credential) => axios.post('/api/Login/Authentication', credential), [])

  return useCallback(() => ({
    loginBroker,
  }), [
    loginBroker,
  ])
}

export default useAuthClient
