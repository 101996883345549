import React from 'react'
import {
  Box,
  Grid,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import { CardTotal } from '../../components'
import { MIGRATION_STATUS } from '../../constants'

const useStyles = makeStyles(() => ({
  row: {
    alignSelf: 'center',
  },

  box: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const BrokerTotal = ({ totalizers, onPress }) => {
  const classes = useStyles()

  return (
    <Box className={classes.box}>
      <Grid container justify="space-around" className={classes.row}>
        <CardTotal
          title="Total de Corretoras"
          qtd={totalizers?.total || 0}
          onSelect={() => onPress([])}
        />

        <CardTotal
          title="Corretoras Migradas"
          qtd={totalizers?.migrated || 0}
          onSelect={() => onPress([
            MIGRATION_STATUS.MIGRATED_BROKER,
            MIGRATION_STATUS.MIGRATED_POTTENCIAL,
          ])}
        />

        <CardTotal
          title="Aguardando Migração"
          qtd={totalizers?.migrating || 0}
          onSelect={() => onPress([
            MIGRATION_STATUS.MIGRATIONG_BROKER,
            MIGRATION_STATUS.MIGRATIONG_POTTENCIAL,
          ])}
        />

        <CardTotal
          title="Migração não iniciada"
          qtd={totalizers?.notMigrated || 0}
          onSelect={() => onPress([MIGRATION_STATUS.NOT_IGRATED])}
        />
      </Grid>
    </Box>
  )
}

BrokerTotal.propTypes = {
  onPress: PropTypes.func.isRequired,
  totalizers: PropTypes.object.isRequired,
}

export default BrokerTotal
