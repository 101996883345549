import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Home from './pages/home/Home'
import User from './pages/users/User'
import Master from './layouts/Master'
import Login from './pages/login/Login'
import Error from './pages/error/Error'
import Auth from './pages/access/Auth'
import Logout from './pages/access/Logout'
import Broker from './pages/broker/Broker'
import PrivateRoute from './security/PrivateRoute'
import BrokerComplement from './pages/brokerComplement/BrokerComplement'

const Routes = () => (
  <Switch>
    <Route path="/error/:status" component={Error} />

    <Route path="/login">
      <Login>
        <Auth />
      </Login>
    </Route>

    <Route path="/logout">
      <Logout />
    </Route>

    <PrivateRoute
      exact
      layout={Master}
      component={User}
      path="/user/list/:contextId"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={Home}
      path="/"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={BrokerComplement}
      path="/broker/complement/:brokerPersonId"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={Broker}
      path="/broker/list"
    />

    <Redirect to="/error/404" />
  </Switch>
)

export default Routes
