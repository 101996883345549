import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import {
  CPFInput,
  useAlert,
  PhoneInput,
} from '../../components'
import TermModal from './TermModal'
import useYup from '../../hooks/useYup'
import YUP_MESSAGE from '../../helpers/yup'
import CodeCheckModal from './CodeCheckModal'
import useSecurity from '../../security/useSecurity'
import useBrokerClient from '../../clients/BrokerClient'
import { useBrokerComplement } from './ComplementContext'

const useStyles = makeStyles(() => ({
  checkboxTerm: {
    paddingRight: '2px',
    paddingTop: '5px',
  },
  buttonTerm: {
    paddingRight: '0px',
  },
}))

const DEFAULT_SPONSER = {
  name: '',
  phone: '',
  email: '',
  document: '',
  trustedDataConfirmation: false,
}

const Sponsor = forwardRef((props, ref) => {
  const termRef = useRef()
  const classes = useStyles()
  const brokerClient = useBrokerClient()

  const { addMsgDanger } = useAlert()
  const { isPottencial } = useSecurity()

  const [show, setShow] = useState(false)
  const [sponsor, setSponsor] = useState({})
  const [emailCode, setEmailCode] = useState({})
  const [openCodeCheck, setOpenCodeCheck] = useState()

  const {
    broker,
    actionNext,
    actionPrevious,
    setBroker,
    onNext,
    onPrevious,
  } = useBrokerComplement()

  const conclude = (data) => {
    const { contextId, id } = broker

    const values = { ...data, contextId, returnUrl: window.ENV.BROKER_URL }
    delete values.trustedDataConfirmation

    return new Promise((resolve) => {
      brokerClient().createSponsor(values).then(() => {
        brokerClient().complete(id, contextId).then(() => {
          resolve()
        }, (error) => {
          addMsgDanger(error.data)
        })
      }, (error) => {
        addMsgDanger(error.data)
      })
    })
  }

  const nextBroker = (data) => {
    const { email, name } = data
    setSponsor({ ...data })

    if (emailCode.email === email) {
      setOpenCodeCheck(true)
    } else {
      brokerClient().emailCheck({ email, name, send: true }).then((response) => {
        setOpenCodeCheck(true)
        setEmailCode({ email, token: response.data })
      }, (error) => {
        addMsgDanger(error.data)
      })
    }
  }

  const nextPottencial = (data) => {
    const { email, name } = data

    brokerClient().emailCheck({ email, name, send: false }).then((response) => {
      conclude({ ...data, token: `${response.data}` }).then(() => {
        onNext()
      })
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  const next = (data) => {
    if (isPottencial()) {
      nextPottencial(data)
    } else {
      nextBroker(data)
    }
  }

  const previous = (data) => {
    const { brokerSponsor = {} } = broker

    const values = {
      ...broker,
      brokerSponsor: {
        ...brokerSponsor,
        ...data,
      },
    }

    delete values.brokerSponsor.trustedDataConfirmation
    setBroker(values)
    onPrevious()
  }

  const {
    cpf: cpfRule,
    phoneOrCellphone: phoneRule,
  } = useYup()

  const formik = useFormik({
    initialValues: { ...DEFAULT_SPONSER },
    validationSchema: Yup.object({
      phone: phoneRule.required(),
      document: cpfRule.required(),
      name: Yup.string().max(200).required(),
      email: Yup.string().email().max(200).required(),
      confirmationEmail: Yup.string().email().max(200).required()
        .test({
          test: (confirmationEmail) => {
            const { email } = formik.values

            let valid = true

            if (confirmationEmail && email) {
              valid = confirmationEmail === email
            }
            return valid
          },
          message: 'E-mail não corresponde.',
        }),
      trustedDataConfirmation: Yup.boolean().test({
        message: YUP_MESSAGE.required,
        test: (value) => (!isPottencial() && actionNext ? value : true),
      }),
    }),
    onSubmit: (data) => {
      if (actionPrevious) {
        previous(data)
      }

      if (actionNext) {
        next(data)
      }
    },
  })

  const { setValues } = formik

  useEffect(() => {
    const {
      brokerSponsor,
      trustedDataConfirmation,
    } = broker || {}

    const {
      name,
      phone,
      email,
      document,
      confirmationEmail,
    } = brokerSponsor || {}

    setValues({
      name: name || '',
      email: email || '',
      phone: phone || '',
      document: document || '',
      confirmationEmail: confirmationEmail || '',
      trustedDataConfirmation: trustedDataConfirmation || false,
    })
  }, [broker, setValues])

  useImperativeHandle(ref, () => ({
    onOpen: () => new Promise((resolve) => {
      setShow(true)
      resolve()
    }),
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: 'Verifique os campos em destaque antes de prosseguir.' }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
    onClose: () => setShow(false),
  }))

  return (
    <>
      {show && (
        <>
          <CodeCheckModal
            code={emailCode}
            open={openCodeCheck}
            onCancel={() => setOpenCodeCheck(false)}
            onSubmit={(data) => {
              conclude({ ...sponsor, token: data?.token }).then(() => {
                onNext()
                setOpenCodeCheck(false)
              })
            }}
          />

          <Paper className="paper">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography
                  color="primary"
                  component="span"
                  className={classes.title}
                >
                  RESPONSÁVEL
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography component="span" variant="body2">
                  Nesta etapa você deverá informar os dados
                  do responsável pelo cadastro da Corretora.
                </Typography>
              </Grid>

              <Grid item sm={3} xs={12}>
                <CPFInput
                  id="document"
                  color="secondary"
                  label="CPF*:"
                  title="CPF"
                  {...formik.getFieldProps('document')}
                  error={formik.touched.document && !!formik.errors.document}
                  helperText={formik.touched.document && formik.errors.document}
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id="name"
                  color="secondary"
                  label="Nome*:"
                  title="Nome"
                  {...formik.getFieldProps('name')}
                  error={formik.touched.name && !!formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name}
                  fullWidth
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <PhoneInput
                  id="phone"
                  color="secondary"
                  label="Telefone*:"
                  title="Telefone"
                  {...formik.getFieldProps('phone')}
                  error={formik.touched.phone && !!formik.errors.phone}
                  helperText={formik.touched.phone && formik.errors.phone}
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id="email"
                  color="secondary"
                  label="E-mail*:"
                  title="E-mail"
                  {...formik.getFieldProps('email')}
                  error={formik.touched.email && !!formik.errors.email}
                  helperText={formik.touched.email && formik.errors.email}
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id="confirmationEmail"
                  color="secondary"
                  label="E-mail de Confirmação*:"
                  title="E-mail de Confirmação"
                  {...formik.getFieldProps('confirmationEmail')}
                  error={formik.touched.confirmationEmail
                    && !!formik.errors.confirmationEmail}
                  helperText={formik.touched.confirmationEmail
                    && formik.errors.confirmationEmail}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>

          {!isPottencial() && (
            <>
              <TermModal
                ref={termRef}
                onAccept={() => {
                  formik.setFieldValue('trustedDataConfirmation', true)
                }}
              />

              <Box ml={1} mt={2}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="primary"
                      id="trustedDataConfirmation"
                      name="trustedDataConfirmation"
                      className={classes.checkboxTerm}
                      checked={formik.values.trustedDataConfirmation}
                      onChange={(event) => {
                        formik.setFieldValue('trustedDataConfirmation', event.target.checked)
                      }}
                    />
              )}
                  label={(
                    <>
                      <Box component="span">Ao concluir o recadastro da corretora, você aceita nossos</Box>
                      <Box component="span">
                        <Button
                          color="primary"
                          className={classes.buttonTerm}
                          onClick={() => termRef.current.onOpen()}
                        >
                          Termos e condições
                        </Button>
                      </Box>
                      <Box component="span">.</Box>
                    </>
              )}
                />
                <Box ml={-1}>
                  <FormHelperText
                    hidden={!formik.touched.trustedDataConfirmation
                  || !formik.errors.trustedDataConfirmation}

                    error={formik.touched.trustedDataConfirmation
                  && !!formik.errors.trustedDataConfirmation}
                  >
                    {formik.errors.trustedDataConfirmation}
                  </FormHelperText>
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </>
  )
})

export default Sponsor
