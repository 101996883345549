import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  uniq,
  remove,
  orderBy,
  isEmpty,
} from 'lodash'
import {
  Box,
  Grid,
  Button,
  Collapse,
  makeStyles,
  Typography,
  FormControlLabel,
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

import {
  ModalInfo,
  CustomSwitch,
} from '../../components'

const useStyles = makeStyles((theme) => ({
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 600,
    },
  },
  switch: {
    '& .MuiFormControlLabel-label': {
      ...theme.typography.body2,
      marginLeft: theme.spacing(1),
    },
  },
}))

const SwitchFunctionality = ({
  functionality,
  onChange: change,
}) => {
  const classes = useStyles()
  const { checked } = functionality
  const [opened, setOpened] = useState(true)

  return (
    <Box>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <FormControlLabel
            className={classes.switch}
            label={functionality?.name || ''}
            control={(
              <CustomSwitch
                checked={checked}
                title={functionality?.name || ''}
                onChange={() => {
                  const value = !checked
                  change(functionality, value)
                }}
              />
            )}
          />
        </Grid>

        {functionality.grouped && (
          <Grid item>
            { opened && (
              <ExpandLess
                color="primary"
                onClick={() => setOpened(false)}
              />
            )}

            { !opened && (
              <ExpandMore
                color="primary"
                onClick={() => setOpened(true)}
              />
            )}
          </Grid>
        )}
      </Grid>

      <Collapse in={opened}>
        <Box pt={1} pl={2}>
          <FunctionalityGroup
            onChange={change}
            functionalities={functionality?.childrens || []}
          />
        </Box>
      </Collapse>
    </Box>
  )
}

SwitchFunctionality.propTypes = {
  onChange: PropTypes.func.isRequired,
  functionality: PropTypes.object.isRequired,
}

const FunctionalityGroup = ({
  column,
  onChange,
  functionalities,
}) => (
  <ul
    style={{
      padding: 0,
      columnCount: column,
      listStyleType: 'none',
    }}
  >
    {
        functionalities.map((functionality, index) => (
          <li key={index}>
            <SwitchFunctionality
              index={index}
              functionality={functionality}
              onChange={onChange}
            />
          </li>
        ))
      }
  </ul>
)

FunctionalityGroup.propTypes = {
  column: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  functionalities: PropTypes.array.isRequired,
}

FunctionalityGroup.defaultProps = {
  column: undefined,
}

const PermissionModal = (props) => {
  const classes = useStyles()
  const [items, setItems] = useState([])

  const {
    opened,
    functionalities,
    userFunctionalities,
    onCancel,
    onSave: save,
  } = props

  useEffect(() => {
    if (!opened) {
      return
    }

    const data = functionalities.map((item) => {
      const checked = userFunctionalities.filter((id) => id === item.id).length !== 0
      return { ...item, checked }
    })

    let current = data.filter((item) => isEmpty(item.group))

    let groups = data.filter((item) => !isEmpty(item.group))
    groups = groups.map((item) => item.group)
    groups = uniq(groups)

    groups.forEach((name) => {
      let childrens = data.filter((item) => item.group === name)
      childrens = orderBy(childrens, (item) => (item?.name || '').toLowerCase(), ['asc'])

      const checked = userFunctionalities.filter((item) => item.group === name).length !== 0

      current.push({
        name,
        checked,
        childrens,
        grouped: true,
      })
    })

    current = orderBy(current, (item) => (item?.name || '').toLowerCase(), ['asc'])
    setItems(current)
  }, [functionalities, userFunctionalities, opened])

  const updateGrouped = (functionality, checked) => {
    let data = [...items]
    const { name } = functionality

    let current = items.find((item) => item.name === name)
    const { childrens } = current

    current = {
      ...current,
      childrens: childrens.map((item) => ({ ...item, checked })),
      checked,
    }

    data = remove(data, (item) => item.name !== name)
    return [...data, current]
  }

  const updateFunctionality = (functionality, checked, data) => {
    const { id, group } = functionality
    let current = data.find((item) => item.id === id)

    if (isEmpty(current)) {
      current = data.find((item) => item.name === group)

      let childrens = updateFunctionality(functionality, checked, current.childrens)
      childrens = orderBy(childrens, (item) => (item?.name || '').toLowerCase(), ['asc'])

      current = {
        ...current,
        childrens,
        checked: childrens.filter((item) => item.checked).length !== 0,
      }

      data = remove(data, (item) => item.name !== group)
    } else {
      current = { ...current, checked }
      data = remove(data, (item) => item.id !== id)
    }

    return [...data, current]
  }

  const handleFunctionality = (functionality, checked) => {
    let data = []
    const { grouped } = functionality

    if (grouped) {
      data = updateGrouped(functionality, checked)
    } else {
      data = updateFunctionality(functionality, checked, items)
    }

    data = orderBy(data, (item) => (item?.name || '').toLowerCase(), ['asc'])
    setItems(data)
  }

  const handleConfirm = () => {
    const data = items.filter((item) => !item.grouped && item.checked)
    const groups = items.filter((item) => item.grouped && item.checked)

    groups.forEach((group) => {
      const childrens = group.childrens.filter((item) => item.checked)
      data.push(...childrens)
    })

    save(data)
  }

  return (
    <ModalInfo
      open={opened}
      onClose={onCancel}
      className={classes.modal}
    >
      <>
        <Box my={3} align="center">
          <Typography variant="h6">Defina as permissões que este usuário terá no portal:</Typography>
        </Box>

        <Box p={2}>
          <FunctionalityGroup
            column={2}
            functionalities={items}
            onChange={handleFunctionality}
          />
        </Box>

        <Box mt={3}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                color="primary"
                title="Cancelar"
                variant="outlined"
                onClick={onCancel}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                title="Salvar"
                color="primary"
                variant="contained"
                onClick={handleConfirm}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    </ModalInfo>
  )
}

PermissionModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  functionalities: PropTypes.array.isRequired,
  userFunctionalities: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default PermissionModal
