import React, { useState } from 'react'
import {
  Box,
  Grid,
  Button,
  TextField,
  makeStyles,
  Typography,
} from '@material-ui/core'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import useYup from '../../hooks/useYup'
import { ModalInfo, useAlert } from '../../components'
import useBrokerClient from '../../clients/BrokerClient'

const useStyles = makeStyles((theme) => ({
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 670,
    },
  },
  attendance: {
    padding: 0,
    paddingLeft: 5,
    fontSize: theme.typography.body2.fontSize,
  },
  resend: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.body2.fontSize,
  },
}))

const CodeCheckModal = ({
  open, code, onSubmit, onCancel,
}) => {
  const classes = useStyles()
  const { onlyNumber } = useYup()
  const { addMsgDanger } = useAlert()
  const brokerClient = useBrokerClient()
  const [resendSuccess, setResendSuccess] = useState(false)

  const formik = useFormik({
    initialValues: { token: '' },
    validationSchema: Yup.object({
      token: onlyNumber.required().test({
        test: (token) => {
          let valid = true

          if (token) {
            valid = token === `${code?.token || ''}`
          }
          return valid
        },
      }),
    }),
    onSubmit,
  })

  const handleEmailCheck = () => {
    const { email, name } = code

    brokerClient().emailCheck({ email, name, send: true }).then(() => {
      setResendSuccess(true)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  return (
    <ModalInfo
      open={open}
      close={false}
      className={classes.modal}
    >
      <Box p={1}>
        <Box mb={3}>
          <Typography align="center" variant="h6">
            Validação de e-mail
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{ __html: `Informe o código de verificação que enviamos para o e-mail <b>${code?.email || ''}</b>.` }}
          />
        </Box>

        <Box mb={3}>
          <TextField
            id="token"
            color="secondary"
            label="Código*:"
            title="Código"
            {...formik.getFieldProps('token')}
            error={formik.touched.token && !!formik.errors.token}
            helperText={formik.touched.token && formik.errors.token}
            fullWidth
          />
        </Box>

        <Box>
          <Typography variant="body2" component="span">
            Aguarde alguns instantes e caso não tenha recebido o código, verifique
            se digitou o e-mail corretamente ou verifique sua caixa de spam.
          </Typography>

          <Button
            variant="text"
            color="primary"
            title="Clique aqui"
            onClick={handleEmailCheck}
            className={classes.resend}
          >
            Clique aqui
          </Button>

          <Typography variant="body2" component="span">
            para reenviar código ou entre em contato conosco
            através de nossos
          </Typography>

          <Button
            component="a"
            variant="text"
            color="primary"
            target="_blank"
            className={classes.attendance}
            title="Canais de Atendimento"
            href={window.ENV.POTTENCIAL_ATENDIMENTO}
          >
            Canais de Atendimento
          </Button>

          <Typography variant="body2" component="span">.</Typography>
        </Box>

        {resendSuccess && (
          <Box mt={2} display="flex" justifyContent="center">
            <Box color="#439508" display="flex" alignItems="center">
              <CheckCircleIcon />
              <Typography variant="body2" component="span">
                Código reenviado!
              </Typography>
            </Box>
          </Box>
        )}

        <Box mt={5}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} sm={5}>
              <Button
                fullWidth
                color="primary"
                title="Cancelar"
                variant="outlined"
                onClick={() => {
                  onCancel()
                  setResendSuccess(false)
                  formik.setFieldValue('token', '')
                }}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button
                color="primary"
                title="Enviar"
                variant="contained"
                fullWidth
                onClick={() => formik.submitForm()}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ModalInfo>
  )
}

CodeCheckModal.propTypes = {
  open: PropTypes.bool,
  code: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

CodeCheckModal.defaultProps = {
  open: false,
}

export default CodeCheckModal
