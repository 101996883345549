import React, {
  useState, Fragment, useMemo, useCallback,
} from 'react'
import {
  Box,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { isEmpty } from 'lodash'
import CloseIcon from '@material-ui/icons/Close'

import useUtils from '../../hooks/useUtils'
import { Resolve, useAlert } from '../../components'
import useBrokerClient from '../../clients/BrokerClient'
import { DESCRIPTION_MIGRATION_STATUS } from '../../constants'

import BrokerTable from './BrokerTable'
import BrokerFilter from './BrokerFilter'
import BrokerTotal from './BrokerTotal'

const useStyles = makeStyles((theme) => ({
  selectedFilter: {
    ...theme.overrides.MuiButton.outlined,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    position: 'relative',
    padding: theme.spacing(1, 5),
    display: 'inline-block',
    '& svg': {
      position: 'absolute',
      top: 4,
      right: 8,
      fontSize: '.85rem',
      background: '#E6E6E6',
      borderRadius: '50%',
      padding: 2,
      cursor: 'pointer',
    },
  },
}))

const DEFAULT_FILTER = {
  document: '',
  statusRenewal: [],
}

const Broker = () => {
  const classes = useStyles()
  const { addMsgDanger } = useAlert()
  const [total, setTotal] = useState(0)
  const brokerClient = useBrokerClient()
  const [brokers, setBrokers] = useState([])
  const { isBlank, formatCPForCNPJ } = useUtils()
  const [totalizers, setTotalizers] = useState({})
  const [filters, setFilters] = useState({ ...DEFAULT_FILTER })

  const handleResolve = useMemo(() => ({
    broker: () => new Promise((resolve, reject) => {
      brokerClient().getBrokers({ pageNumber: 1 }).then((response) => {
        resolve(response.data)
      }, (error) => {
        reject()
        addMsgDanger(error.data)
      })
    }),
    brokerTotalizers: () => new Promise((resolve, reject) => {
      brokerClient().getRegistrationTotalizers().then((response) => {
        resolve(response.data)
      }, (error) => {
        reject()
        addMsgDanger(error.data)
      })
    }),
  }), [brokerClient, addMsgDanger])

  const handleLoaded = useCallback((data, resolve) => {
    const { broker, brokerTotalizers } = data

    // Brokkers
    setTotal(broker.total)
    setBrokers(broker.data)

    // Totalizers
    setTotalizers(brokerTotalizers)
    resolve()
  }, [])

  const onSearch = (filter) => {
    brokerClient().getBrokers(filter).then((response) => {
      const { data: broker } = response
      setBrokers(broker.data)

      const count = isEmpty(broker.data) ? 0 : broker.total
      setTotal(count)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  const handleRemoveDocumentFilter = () => {
    const data = { ...filters, document: '' }
    onSearch({ ...data, pageNumber: 1 })
    setFilters(data)
  }

  const handleRemoveStatusFilter = (removeId) => {
    const { statusRenewal } = filters
    const data = {
      ...filters,
      statusRenewal: statusRenewal.filter((id) => id !== removeId),
    }

    onSearch({ ...data, pageNumber: 1 })
    setFilters(data)
  }

  return (
    <Resolve
      onLoaded={handleLoaded}
      resolve={handleResolve}
    >
      <>
        <Box mb={4}>
          <Typography variant="h4">Recadastramento de Corretoras</Typography>
        </Box>

        <Box mt={2} mb={4}>
          <BrokerTotal
            totalizers={totalizers}
            onPress={(statusRenewal) => {
              const data = { ...DEFAULT_FILTER, statusRenewal: [...statusRenewal] }
              onSearch({ ...data, pageNumber: 1 })
              setFilters(data)
            }}
          />
        </Box>

        <Box mt={8} mb={4}>
          <Grid container justify="flex-end">
            <BrokerFilter
              filters={filters}
              addFilter={(data) => {
                onSearch({ ...data, pageNumber: 1 })
                setFilters(data)
              }}
            />
          </Grid>
        </Box>

        <Box>
          {Object.keys(filters).map((attribute, index) => (
            <Fragment key={index}>
              { attribute === 'document' && !isBlank(`${filters.document}`) && (
                <Box component="span" mr={1} my={1 / 2} className={classes.selectedFilter}>
                  {formatCPForCNPJ(filters.document)}
                  <CloseIcon
                    color="action"
                    onClick={handleRemoveDocumentFilter}
                  />
                </Box>
              )}

              { attribute === 'statusRenewal' && !isEmpty(filters.statusRenewal) && (
                filters.statusRenewal.map((statusId) => (
                  <Box key={statusId} component="span" mr={1} my={1 / 2} className={classes.selectedFilter}>
                    {DESCRIPTION_MIGRATION_STATUS[statusId]}
                    <CloseIcon color="action" onClick={() => handleRemoveStatusFilter(statusId)} />
                  </Box>
                ))
              )}
            </Fragment>
          ))}
        </Box>

        <BrokerTable
          total={total}
          brokers={brokers}
          nextPage={(pageNumber) => onSearch({ ...filters, pageNumber })}
        />
      </>
    </Resolve>
  )
}

export default Broker
