import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { SlashScreen } from '../../components'
import useSecurity from '../../security/useSecurity'

const Home = () => {
  const history = useHistory()
  const { getBrokerPersonId } = useSecurity()

  useEffect(() => {
    const brokerPersonId = getBrokerPersonId()

    if (brokerPersonId) {
      history.push(`/broker/complement/${brokerPersonId}`)
    } else {
      history.push('/broker/list')
    }
  }, [history, getBrokerPersonId])

  return (<SlashScreen />)
}

export default Home
