import { useCallback } from 'react'
import axios from 'axios'

const useBankClient = () => {
  const getBanks = useCallback(() => axios.get('/api/resources/banks'), [])

  const editBank = useCallback((bank) => axios.put(`/api/registration/bankReference/${bank.brokerId}`, bank, { headers: { context_id: bank.contextId } }), [])

  return useCallback(() => ({
    getBanks,
    editBank,
  }), [
    getBanks,
    editBank,
  ])
}

export default useBankClient
