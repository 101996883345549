import React from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles, Grid, Button, Typography,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      justifyContent: 'center',
    },
  },
  welcome: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  btn: {
    color: theme.palette.primary.contrastText,
    fontSize: '1.05rem',
  },
  borderLogout: {
    borderBottom: '1px solid',
    paddingBottom: '1px',
  },
  containerTheme: {
    [theme.breakpoints.up('md')]: {
      padding: '0 10px',
      margin: '0 10px',
      border: `solid ${theme.palette.primary.contrastText}`,
      borderWidth: '0 0 0 1px',
    },
  },

}))

const HeaderMenu = ({ logout }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      alignItems="center"
      justify="flex-end"
      className={classes.root}
    >
      <Grid item className={classes.welcome}>
        <Typography variant="body2">Olá! Seja bem-vindo ao Portal do Corretor!</Typography>
      </Grid>
      <Grid item className={classes.containerTheme}>
        <Button
          className={`${classes.btn} btn-theme`}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={logout}
          title="Sair"
        >
          <span className={classes.borderLogout}>Sair</span>
        </Button>
      </Grid>
    </Grid>
  )
}

HeaderMenu.propTypes = {
  logout: PropTypes.func.isRequired,
}

export default HeaderMenu
