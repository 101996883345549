import React, {
  useMemo,
  useState,
} from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ThemeProvider } from '@material-ui/core'

import {
  Loader,
  AlertProvider,
} from './components'
import Routes from './Routes'
import { createTheme } from './theme'
import useSettings from './hooks/useSettings'

import './helpers/yup'
import './assets/style/global.scss'
import AxiosSetting from './helpers/axios'

const history = createBrowserHistory()

const App = () => {
  const [loading, setLoading] = useState(false)

  const { getColor } = useSettings()
  const color = getColor()
  const theme = createTheme(color)

  const handleError = useMemo(() => ({
    401: () => {
      setTimeout(() => history.push('/logout'))
    },
    403: () => {
      history.push('/')
    },
  }), [])

  return (
    <>
      <AxiosSetting
        handleError={handleError}
        onStartRequest={() => setLoading(true)}
        onStopRequest={() => setLoading(false)}
      />

      <ThemeProvider theme={theme}>
        <AlertProvider />
        <Loader show={loading} />
        <Router history={history}>
          <Routes />
        </Router>
      </ThemeProvider>
    </>
  )
}

export default App
