import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'
import { TextField } from '@material-ui/core'

export const SUSEP_MASK = '99.999.999-9'

const SUSEPInput = (props) => {
  const field = useMemo(() => {
    const inputProps = {
      ...props,
      onBlur: undefined,
      onChange: undefined,
    }

    return <TextField {...inputProps} />
  }, [props])

  return (
    <InputMask
      {...props}
      maskChar={null}
      mask={SUSEP_MASK}
    >
      {() => field}
    </InputMask>
  )
}

SUSEPInput.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

SUSEPInput.defaultProps = {
  onBlur: () => { },
  onChange: () => { },
}

export default SUSEPInput
