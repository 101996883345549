import { merge } from 'lodash'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'

import MuiBaseConfig from './MuiBaseConfig'
import { DEFAULT_THEME } from '../constants'

export const createTheme = (color = DEFAULT_THEME) => {
  const configs = {
    name: color.id,
    palette: {
      primary: {
        main: color.primary,
      },
      secondary: {
        main: '#EFEFEF',
        contrastText: '#8B7676',
      },
      text: {
        primary: '#3C3C3C',
      },
      error: {
        main: '#C3284C',
      },
    },
  }

  const theme = createMuiTheme(merge({}, MuiBaseConfig, configs))

  return responsiveFontSizes(theme)
}

export default createTheme
