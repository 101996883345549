import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { isEmpty } from 'lodash'
import Box from '@material-ui/core/Box'
import ReactTooltip from 'react-tooltip'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import { useSelector } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import { useParams } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import CardContent from '@material-ui/core/CardContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import {
  Resolve,
  useAlert,
} from '../../components'
import UserForm from './UserForm'
import useUserClient from '../../clients/UserClient'
import { useSecurityAction } from '../../store/ducks/security'
import useFunctionalityClient from '../../clients/FunctionalityClient'

import icoAlert from '../../assets/img/ico-alert-warning.svg'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
  },
  card: {
    '& .MuiCardContent-root': {
      padding: theme.spacing(1.5),
    },
  },
  success: {
    color: '#46835B',
    '& svg': {
      marginTop: '3px',
    },
  },
  error: {
    color: '#BE431D',
    '& svg': {
      marginTop: '3px',
    },
  },
  imgAlert: {
    textAlign: 'center',
    '& img': {
      maxWidth: 100,
      textAlign: 'center',
    },
  },
}))

const User = () => {
  const classes = useStyles()
  const { contextId } = useParams()
  const userClient = useUserClient()
  const { addMsgDanger } = useAlert()
  const { cleanUser } = useSecurityAction()
  const functionalityClient = useFunctionalityClient()

  const [users, setUsers] = useState([])
  const [openedReport, setOpenedReport] = useState(false)
  const [usersReport, setUsersReport] = useState([])
  const [functionalities, setFunctionalities] = useState([])

  const { revised, blacklist } = useSelector(({ user }) => ({
    revised: user.users,
    blacklist: user.blacklist,
  }))

  const resolveBlackList = useCallback((blackItems, currents = []) => currents.filter((item) => {
    const { userId } = item
    return !blackItems.find((black) => black.userId === userId)
  }), [])

  const resolveRevised = useCallback((revisedItems, data) => {
    const currents = [...data]

    revisedItems.forEach((item) => {
      const value = currents.find((current) => current.userId === item.userId)

      if (!isEmpty(value)) {
        currents[data.indexOf(value)] = { ...item }
      }
    })
    return currents
  }, [])

  useEffect(() => {
    setUsers((data) => resolveBlackList(blacklist, data))
  }, [blacklist, resolveBlackList])

  useEffect(() => {
    setUsers((data) => resolveRevised(revised, data))
  }, [revised, resolveRevised])

  const disabledMigration = useMemo(() => {
    const count = users.filter((item) => !item.reviewed).length
    return count !== 0
  }, [users])

  const handleResolve = useMemo(() => ({
    currentUsers: () => new Promise((resolve, reject) => {
      userClient().getUsers(contextId).then((response) => {
        const { data = [] } = response.data
        resolve(data)
      }, (response) => {
        reject()
        addMsgDanger(response.data)
      })
    }),
    currentFunctionalities: () => new Promise((resolve, reject) => {
      functionalityClient().getFunctionalities().then((response) => {
        resolve(response.data)
      }, (response) => {
        reject()
        addMsgDanger(response.data)
      })
    }),
  }), [contextId, userClient, functionalityClient, addMsgDanger])

  const handleLoaded = useCallback((data, resolve) => {
    const { currentUsers, currentFunctionalities } = data

    let currents = currentUsers.map((user) => ({
      ...user,
      id: contextId,
      userId: user.id,
    }))

    currents = resolveBlackList(blacklist, currents)
    currents = resolveRevised(revised, currents)

    setFunctionalities(currentFunctionalities)
    setUsers(currents)
    resolve()
  }, [resolveBlackList, resolveRevised]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleMigrateUsers = () => {
    const data = {
      users,
      contextId,
      returnUrl: window.ENV.BROKER_URL,
    }

    userClient().migrateUsers(data).then((response) => {
      setUsersReport(response.data)
      setOpenedReport(true)
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  const handleSuccess = () => {
    window.location.href = window.ENV.BROKER_URL
    setTimeout(() => cleanUser())
  }

  return (
    <Resolve
      onLoaded={handleLoaded}
      resolve={handleResolve}
    >
      <main>
        <Box mb={2}>
          <Typography variant="h5">Recadastramento de Corretoras</Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="body2">
            Favor revisar os dados dos usuários e completar os campos faltantes
            para migrar os usuários da corretora. Caso não queira migrar
            determinado usuário, basta excluí-lo.
          </Typography>
        </Box>

        <Box mb={2}>
          <Paper>
            <Box p={2}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={1} className={classes.imgAlert}>
                  <img src={icoAlert} alt="Alerta" />
                </Grid>
                <Grid item xs={12} sm={11}>
                  <Typography component="span" variant="body2">
                    {`Atenção! Caso você não conclua a migração dos usuários nessa etapa, 
                      não será possível retornar a esta página. Para fazer os cadastros será 
                      necessário acessar o novo Portal do Corretor em menu > usuários.`}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box>
          {users.map((user, index) => (
            <UserForm
              key={index}
              user={user}
              index={index}
              functionalities={functionalities}
            />
          ))}
        </Box>

        <Box mt={4}>
          <Grid container justify="center">
            <Grid
              item
              sm={2}
              xs={12}
              data-html
              data-tip="Para concluir a migração, você deve completar<br /> e salvar os dados daqueles que deseja migrar<br /> e excluir os usuários que não deseja migrar."
            >
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleMigrateUsers}
                disabled={disabledMigration}
              >
                Migrar Usuários
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Dialog
          open={openedReport}
          onClose={() => setOpenedReport(false)}
        >
          <>
            <DialogTitle>
              <Box my={3} align="center">
                <Typography variant="h6">Relatório de migração dos usuários</Typography>
              </Box>
            </DialogTitle>

            <Box mx={3} mb={4}>
              <Typography variant="body2">
                A migração dos usuários foi finalizada.
                Confira abaixo o status de migração de cada usuário:
              </Typography>
            </Box>

            <DialogContent>
              {usersReport.map((user, index) => (
                <Box key={index} pt={1}>
                  <Card className={classes.card} elevation={2}>
                    <CardContent>
                      <Grid
                        container
                        spacing={5}
                        justify="space-between"
                      >
                        <Grid item xs={4}>
                          <Typography variant="body2" className={classes.title}>Nome:</Typography>
                          <Typography variant="body2">{user.name}</Typography>
                        </Grid>

                        <Grid item xs={7}>
                          <Typography variant="body2" className={classes.title}>Status:</Typography>
                          {user.success && (
                            <Grid alignItems="center" container className={classes.success}>
                              <Grid item>
                                <DoneIcon fontSize="small" />
                              </Grid>
                              <Grid item>
                                Migrado com sucesso
                              </Grid>
                            </Grid>
                          )}

                          {!user.success && (
                            <>
                              <Grid alignItems="center" container className={classes.error}>
                                <Grid item>
                                  <CloseIcon fontSize="small" />
                                </Grid>
                                <Grid item>
                                  Erro ao migrar
                                </Grid>
                              </Grid>
                              <Typography variant="body2">{user.message}</Typography>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              ))}
            </DialogContent>

            <DialogActions>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Box p={2}>
                    <Button
                      fullWidth
                      color="primary"
                      title="OK"
                      variant="contained"
                      onClick={handleSuccess}
                    >
                      OK
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        </Dialog>

        <ReactTooltip place="top" type="dark" />
      </main>
    </Resolve>
  )
}

export default User
