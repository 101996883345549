import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { SlashScreen } from '../../components'
import { useSecurityAction } from '../../store/ducks/security'

const Logout = () => {
  const history = useHistory()
  const { cleanUser } = useSecurityAction()

  useEffect(() => {
    cleanUser()
    history.push('/login')
  }, [history, cleanUser])

  return <SlashScreen />
}

export default Logout
