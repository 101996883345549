import React from 'react'
import PropTypes from 'prop-types'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import {
  Hidden, MobileStepper, Button, Box, Grid, useTheme, makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  stepper: {
    marginTop: theme.spacing(2),
  },
}))

const StepControl = ({
  steps, activeStep, handleNext, handleBack, hasSuccess, finishText,
}) => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <>
      {/* BOTÕES DE CONTROLE - MOBILE */}
      <Hidden smUp>
        <MobileStepper
          variant="progress"
          steps={hasSuccess ? steps.length + 1 : steps.length}
          position="static"
          activeStep={activeStep}
          className={classes.stepper}
          backButton={(
            <Button
              size="small"
              onClick={handleBack}
            >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              {activeStep === 0 ? 'Cancelar' : 'Voltar'}
            </Button>
          )}
          nextButton={(
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === steps.length}
              variant={activeStep === steps.length - 1 ? 'contained' : 'outlined'}
              color="primary"
            >
              {activeStep === steps.length - 1 ? finishText : 'Próximo'}
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          )}
        />
      </Hidden>
      {/* BOTÕES DE CONTROLE - DESKTOP */}
      <Hidden xsDown>
        <Box mt={3}>
          <Grid container justify="flex-end">

            {
              activeStep !== steps.length
                ? (
                  <>
                    <Grid item>

                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleBack}
                      >
                        {activeStep === 0 ? 'Cancelar' : 'Voltar'}
                      </Button>

                    </Grid>
                    <Grid item>
                      <Box ml={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                        >
                          {activeStep === steps.length - 1 ? finishText : 'Próximo'}
                        </Button>
                      </Box>
                    </Grid>
                  </>
                )
                : (
                  <Grid item>
                    <Button
                      color="primary"
                      variant="outlined"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Voltar
                    </Button>
                  </Grid>
                )
            }
          </Grid>
        </Box>
      </Hidden>
    </>
  )
}

StepControl.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  finishText: PropTypes.string.isRequired,
  hasSuccess: PropTypes.func,
}

StepControl.defaultProps = {
  hasSuccess: undefined,
}

export default StepControl
