import { useCallback } from 'react'
import axios from 'axios'

const useFunctionalityClient = () => {
  const getFunctionalities = useCallback(() => axios.get('/api/functionality'), [])

  return useCallback(() => ({
    getFunctionalities,
  }), [
    getFunctionalities,
  ])
}

export default useFunctionalityClient
