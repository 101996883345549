import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Pagination from '@material-ui/lab/Pagination'
import { Box } from '@material-ui/core'

const Paginator = ({ totalResults, changePaginator }) => {
  const RPP = 10

  const [page, setPage] = useState(1)
  const handleChange = (event, value) => {
    setPage(value)
    changePaginator(value)
  }

  return totalResults > RPP ? (
    <Box display="flex" justifyContent="center" mt={4}>
      <Pagination count={Math.ceil(totalResults / RPP)} page={page} onChange={handleChange} showFirstButton showLastButton justify="center" />
    </Box>
  ) : ''
}
Paginator.propTypes = {
  totalResults: PropTypes.number.isRequired,
  changePaginator: PropTypes.func.isRequired,
}

Paginator.defaultProps = {}

export default Paginator
