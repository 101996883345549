import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  Hidden,
  Container,
  makeStyles,
  IconButton,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu'

import HeaderMenu from './HeaderMenu'
import logo from '../../assets/img/logo.jpg'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: theme.sizes.header,
    display: 'flex',
    alignItems: 'center',
  },
  hasLogo: {
    [theme.breakpoints.down('xs')]: {
      height: `calc(${theme.sizes.header}px + 50px)`,
    },
  },
  title: {
    display: 'flex',
    width: 160,
    '& img': {
      maxWidth: '100%',
    },
  },
  titleMultiple: {
    '& img': {
      maxWidth: '100%',
    },
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  headerMenu: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  containerMenu: {
    [theme.breakpoints.down('lg')]: {
      marginLeft: 'auto',
    },
  },
  clientLogo: {
    maxHeight: 50,
    maxWidth: 180,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}))

const Header = ({
  handleDrawerToggle,
  logoClient,
  logout,
}) => {
  const classes = useStyles()

  return (
    <header
      className={`${classes.root} ${logoClient !== null ? classes.hasLogo : ''} `}
    >
      <Container maxWidth="xl">
        <Grid container alignItems="center">
          <Grid item xs={logoClient !== null ? 6 : 'auto'} sm={false}>
            <Box
              className={
                logoClient !== null
                  ? classes.titleMultiple
                  : classes.title
              }
            >
              <Link to="/">
                <img src={logo} alt="Logo" />
              </Link>
            </Box>
          </Grid>
          {
            logoClient ? (
              <Grid item xs={6} sm="auto">
                <Box mx={2}>
                  <img
                    className={classes.clientLogo}
                    src={logoClient}
                    alt="Logo do cliente"
                  />
                </Box>
              </Grid>
            ) : ''
          }
          <Grid
            item
            md
            className={classes.containerMenu}
            xs={logoClient !== null ? 12 : 'auto'}
            sm="auto"
          >
            <Box className={classes.headerMenu}>
              <Hidden only="sm">
                <HeaderMenu
                  logout={logout}
                />
              </Hidden>
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Container>
    </header>
  )
}

Header.propTypes = {
  logoClient: PropTypes.string,
  logout: PropTypes.func.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
}

Header.defaultProps = {
  logoClient: '',
}

export default Header
