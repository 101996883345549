import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import { Container, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'

const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: `calc(100% - ${theme.sizes.header}px)`,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      '&::before': {
        content: "''",
        position: 'absolute',
        boxShadow: '0 3px 6px #00000029',
        left: '-100%',
        right: '-100%',
        top: 0,
        height: 290,
        zIndex: -1,
        background: theme.palette.primary.main,
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
    },
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainer: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  container: {
    height: '100%',
    flex: '1 1 100%',
  },
  card: {
    minHeight: '100%',
    display: 'flex',
    width: '100%',
    height: '100%',
    borderRadius: '14px 14px 0 0',
    [theme.breakpoints.down('sm')]: {
      boxShadow: '0 0 0 transparent',
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 3),
    },
  },
  cardBody: {
    minHeight: '100%',
    width: '100%',
  },
  btn: {
    width: '100%',
  },
}))

const Main = ({
  children,
}) => {
  const classes = useStyles()

  return (
    <Container maxWidth="xl" className={classes.main}>
      <Grid container className={classes.container}>
        <Grid item className={classes.mainContainer}>
          <Card className={classes.card}>
            <CardContent className={classes.cardBody}>
              {children}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

Main.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Main
