import { useCallback } from 'react'
import { useSelector } from 'react-redux'

const useSecurity = () => {
  const { user } = useSelector(({ security }) => ({
    user: security.user,
  }))

  const getUser = useCallback(() => user, [user])

  const getName = useCallback(() => user?.name || '', [user])

  const getPersonId = useCallback(() => user?.personId, [user])

  const getUsername = useCallback(() => user?.userName || '', [user])

  const getAccessToken = useCallback(() => user?.accessToken, [user])

  const getBrokerPersonId = useCallback(() => user?.brokerPersonId, [user])

  const isPottencial = useCallback(() => !!user?.isPottencial || false, [user])

  const isLoggedIn = useCallback(() => !!user?.accessToken, [user])

  return {
    getUser,
    getName,
    getPersonId,
    getUsername,
    isPottencial,
    getAccessToken,
    getBrokerPersonId,
    isLoggedIn,
  }
}

export default useSecurity
