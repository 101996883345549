import { useCallback } from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment'
import 'moment/locale/pt-br'

import { CPF_SIZE_WITHOUT_MASK } from '../components'

moment.locale('pt-br')

const useUtils = () => {
  const getOnlyNumber = useCallback((value = '') => value.replace(/\D/g, ''), [])

  const isBlank = useCallback((value) => isEmpty(value) || value.trim().length === 0, [])

  const formatNumber = useCallback((value, regex, format) => {
    let formatted = ''

    if (!isBlank(value)) {
      const number = getOnlyNumber(value)
      formatted = number.replace(regex, format)
    }
    return formatted
  }, [isBlank, getOnlyNumber])

  const formatCEP = useCallback((value) => formatNumber(value, /(\d{2})(\d{3})(\d{3})/, '$1.$2-$3'), [formatNumber])

  const formatCPF = useCallback((value) => formatNumber(value, /(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'), [formatNumber])

  const formatCNPJ = useCallback((value) => formatNumber(value, /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'), [formatNumber])

  const formatCPForCNPJ = useCallback((value) => {
    let formatted = ''

    if (!isBlank(value)) {
      const number = getOnlyNumber(value)
      formatted = number.length === CPF_SIZE_WITHOUT_MASK ? formatCPF(number) : formatCNPJ(number)
    }
    return formatted
  }, [formatCPF, formatCNPJ, isBlank, getOnlyNumber])

  const formatSUSEP = useCallback((value) => formatNumber(value, /(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4'), [formatNumber])

  const date = useCallback((value) => (value ? moment(value) : moment()), [])

  const formatHours = useCallback((value) => value && moment(value).format('LT'), [])

  const formatDate = useCallback((value) => value && moment(value).format('DD/MM/YYYY'), [])

  const formatDateAndHours = useCallback((value) => value && moment(value).format('DD/MM/YYYY LT'), [])

  const formatDateApi = useCallback((value) => value && moment(value).format('YYYY/MM/DD'), [])

  const formatDateNews = useCallback((value) => value && `${moment(value).format('DD')} de ${moment(value).format('MMMM YYYY')}`, [])

  const formatPhoneOrCellphone = useCallback((value) => {
    let formatted = ''

    if (!isBlank(value)) {
      let number = getOnlyNumber(value)
      number = number.replace(/^(\d{2})(\d)/g, '($1) $2')
      formatted = number.replace(/(\d)(\d{4})$/, '$1-$2')
    }

    return formatted
  }, [isBlank, getOnlyNumber])

  return {
    date,
    isBlank,
    formatCPF,
    formatCEP,
    formatDate,
    formatCNPJ,
    formatHours,
    formatSUSEP,
    formatDateApi,
    getOnlyNumber,
    formatDateNews,
    formatCPForCNPJ,
    formatDateAndHours,
    formatPhoneOrCellphone,
  }
}

export default useUtils
